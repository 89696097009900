import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { any, func, number, string } from 'prop-types';
import styled from 'styled-components';
import { Modal, Overflow, Block, SecondaryBtnHigh, FilledButton, RadioListCircle, TextAccentSmall } from "base/styled";
import network from 'base/network';

const Box = styled(Block)`
  background: #FFD0A7;
  padding: 30px ; 
  margin: 0 auto;
  min-width: 595px;
  width: 20%;
  height: 420px;
  overflow-y: scroll;
  justify-content: space-between;
  &.sent {
    background: #fff url('https://assets.visitorqueue.com/images/banners/thanks.svg') no-repeat center/ 106%;
    margin: 0 auto;
    text-align: center;
    div {
      max-width: 300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const Card = styled.div`
  width: 100%;
  h2 {
    font-size: 36px;
    color: #3B3E45;
    font-weight: 800;
    margin: 0 0
  }
`;

const Content = styled.form`
  padding-left: 10px

  @media (max-width: 768px) {
    padding-left: 10px
  }
`;

const Buttons = styled.div`
  display: flex;
  padding: 20px 10px 0;
  margin-left: auto;
  justify-content: end;
  button {
    margin-left: 10px;
  }
`;

const Radio = styled(RadioListCircle)`
  padding: 10px 10px 0;
  label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #3B3E45;
  }
  div {
      padding: 8px 0;
      input, input:checked {
        &:before{
          left: -14px;
        }
      }
  }
`;

const RadioBox = styled.div`
  padding: 25px 20px 0;
  textarea {
    min-height: 80px;
  }
`;

export default function Survey({handleSurveyOff, types, id, email}) {
  const { t: onboardingT } = useTranslation('onboarding');
  const { t: homeT } = useTranslation('home');
  const [radio, setRadio] = useState();
  const [text, setText] = useState('');
  const [isSent, setIsSent] = useState(false);

  function handleChange(e) {
    setRadio(e.target.value);
  }

  useEffect(() => {
    if (isSent) {
      const timer = setTimeout(() => {
        handleSurveyOff();
      }, 3000);
      return () => clearTimeout(timer);
    }
    /* eslint-disable */
  }, [isSent]);
    /* eslint-enable */

  const handleSurvey = async () => {
    await network
      .post('/api/surveys', {
        email: email, 
        survey_id: id, 
        skipped: radio == 'skip',
        answers: {
          "What would you pay extra for on your subscription?": radio
        }
      })
      .then(({ data }) => {
        console.log(data);
        setIsSent(true);
      })
      .catch((error) => console.log(error));
  };

  function handleSubmit(e){
    e.preventDefault();
    if (!radio && text == '') {
      setText('Please choose one');
      return;
    }else if (!radio || radio =='') {
      setText('Please choose one');
      return;
    } else {
      setText('');
      handleSurvey();
      setIsSent(true);
    }
  }
  
  function sendInfo() {
    setRadio('skip');
  }

  useEffect(() => {
    if (radio === 'skip') {
      handleSurvey();
    }
    /* eslint-disable */
  }, [radio]);
    /* eslint-enable */

  return (
    <Modal className='over'>
      <Overflow onClick={isSent ? handleSurveyOff : null}></Overflow>
      <Box className={isSent ? 'sent' : '' }>
        <Card>
          {isSent ? (
            <h2>{onboardingT('thank')}</h2>):
            ( 
              <Content onSubmit={handleSubmit}>
                <h2>{onboardingT('surveyTitle')}</h2>
                <RadioBox>
                  <Radio>
                    {types?.map((e, idx) => (
                      <div key={idx}>
                        <input
                          type="radio"
                          checked={radio === e}
                          id={e}
                          value={e}
                          onChange={handleChange}
                        />
                        <label className="radio" htmlFor={e}>
                          {e}
                        </label>
                      </div>
                    ))}
                  </Radio>
                </RadioBox>
                <TextAccentSmall>{text}</TextAccentSmall>
                <Buttons>
                  <SecondaryBtnHigh onClick={sendInfo}>
                    {homeT('skip')}
                  </SecondaryBtnHigh>
                  <FilledButton type='submit'>
                    {homeT('submit')}
                  </FilledButton>
                </Buttons>
              </Content>
            )} 
        </Card>   
      </Box>
    </Modal>
   
  );
}

Survey.propTypes = {
  handleSurveyOff: func,
  setSuccess: func, 
  id: number,
  types: any,
  email: string
};
