import {
  Block,
  TitleTwo,
  BlockMargin,
  FilledButton,
  RadioListCircle,
  TextArea,
  TextAccentSmall,
  SecondaryBtnHigh,
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { any, func } from 'prop-types';
import { ReactComponent as Icon } from 'assets/images/cat_icon.svg';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const Buttons = styled.div`
  display: flex;
  padding: 20px;
  margin:0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const Box = styled(Block)`
  margin: 0 auto;
  flex-direction: column;
  padding: 35px 0 10px 0;
  max-width: 430px;
  svg {
    text-align: center;
    margin: 0 auto;
  }
  h2 {
    margin-top: 25px;
  }
`;

const Content = styled.div`
  padding: 5px 30px 10px;
  text-align: center;
  p {
      max-width: 275px;
      line-height: 23px;
      text-align: center
  }
`;

const Radio = styled(RadioListCircle)`
  padding: 0 40px 20px;
  label {
    font-size: 0.875rem;
    font-weight: 600;
  }
  div {
      padding: 3px 0;
      input, input:checked {
        &:before{
          left: -14px;
        }
      }
  }
`;

const RadioBox = styled.div`
  padding: 25px 20px 0;
  textarea {
    min-height: 80px;
  }
`;

const types = [
  'Technical issues', 
  'Too expensive', 
  'Switching to another product', 
  'Shutting down the company', 
  'Not sure how to use the data', 
  'Missing features I need', 
  'Other'
];

export default function SelectDeactivate({ radio, text,
  handleSubscribeOff,  setRadio, setText, handleDeactivateAcc, handleDeactivateSurvey }) {
  const { t: companyT } = useTranslation('company');
  const { t: homeT } = useTranslation('home');
  const [required, setRequired] = useState(radio=='other');
  const [message, setMessage] = useState('');

  function handleChange(e) {
    setRadio(e.target.value);
    setText('');
  }

  function handleText(e) {
    setText(e.target.value);
  }

  useEffect(() => {
    if (radio=='Other' || radio=='Technical issues' || radio =='Missing features I need') {
      setRequired(true);
    } else {
      setRequired(false);
      
    }
    setMessage(false);
  }, [radio]);

  function handleSubmit(e){
    e.preventDefault();
    if (required && text == '') {
      setMessage('Please explain');
      return;
    }else if (!radio || radio =='') {
      setMessage('Please choose one');
      return;
    } else if (radio && radio =='Switching to another product') {
      handleDeactivateSurvey();
      return;
    }  else {
      handleDeactivateAcc();
    }
  }
 
  return (
    <Box>
      <Icon />
      <TitleTwo>{companyT('wereSad')}</TitleTwo>
      <Content>{companyT('beforeCancel')}</Content>
      <form>
        <RadioBox>
          <Radio>
            {types?.map((e, idx) => (
              <div key={idx}>
                <input
                  type="radio"
                  checked={radio === e}
                  id={e}
                  value={e}
                  onChange={handleChange}
                />
                <label className="radio" htmlFor={e}>
                  {e}
                </label>
              </div>
            ))}
          </Radio>
          { required &&
            (<>
              <p>Please explain below</p>
              <TextArea 
                type="text"
                value={text}
                onChange={handleText}
                required={required}
              />
            </>)
          }
          <TextAccentSmall>{message}</TextAccentSmall>
        </RadioBox>
        <Buttons>
          <BlockMargin>
            <SecondaryBtnHigh type="reset" onClick={handleSubscribeOff}>
              {homeT('cancel')}
            </SecondaryBtnHigh>
          </BlockMargin>
          <BlockMargin>
            <FilledButton onClick={handleSubmit}>
              {companyT('deactivate')}
            </FilledButton>
          </BlockMargin>
        </Buttons>
      </form>
    </Box>
  );
}

SelectDeactivate.propTypes = {
  handleSubscribeOff: func,
  radio: any,
  text: any,
  setRadio: func,
  setText: func,
  handleDeactivateAcc: func,
  handleDeactivateSurvey: func
};
  